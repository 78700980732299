import '@fortawesome/fontawesome-free/js/all';
import '../Style/Alert.css';

//設定按鈕樣式 Class
let AlertBoxSetting = {
  OkText: '確定', //確認 按鈕文字
  CancelText: '取消', //取消 按鈕文字
  OkClass: '', //確認 按鈕樣式
  CancelClass: '', //取消 按鈕樣式
};

let AlertBoxUidCode = 0; //唯一值變數

export let alertBox = function (Setting) {
  //唯一值變數
  AlertBoxUidCode++;

  Setting.Mode = Setting.Mode ? Setting.Mode : 'alert'; //使用模式
  Setting.Title = Setting.Title
    ? Setting.Title
    : '<i class="fa-solid fa-rss"></i>提示訊息'; //Html內容
  Setting.Html = Setting.Html ? Setting.Html : ''; //Html內容
  Setting.OnOK = Setting.OnOK ? Setting.OnOK : () => {}; //確認後 執行函數
  Setting.OnClose = Setting.OnClose ? Setting.OnClose : () => {}; //關閉前 執行函數
  Setting.Code = Setting.Mode + '_' + AlertBoxUidCode;
  Setting.BtnText = Setting.BtnText
    ? Setting.BtnText
    : { Cancel: '取消', OK: '確認' };

  if (Setting.Mode == 'A') {
    Setting.Mode = 'alert';
  } else if (Setting.Mode == 'C') {
    Setting.Mode = 'confirm';
  }

  let tmpBox = document.createElement('div');
  tmpBox.setAttribute('dom-code', `${Setting.Code}`);
  tmpBox.setAttribute('class', `AlertBoxDiv`);
  document.body.appendChild(tmpBox);

  tmpBox = document.querySelector(`.AlertBoxDiv[dom-code="${Setting.Code}"]`);

  tmpBox.innerHTML = `
  <div class="AlertBoxDivContent">
    <div class="AlertBoxDivTitle">
      ${Setting.Title}
    </div>
    <div class="AlertBoxDivHtml"> ${Setting.Html}</div>
    <div class="AlertBoxDivCtrl">
      ${
        Setting.Mode == 'confirm'
          ? `<button id="${Setting.Code + '_Cancel'}">${
              Setting.BtnText.Cancel
            }</button>`
          : ''
      }
      <button id="${Setting.Code + '_OK'}">${Setting.BtnText.OK}</button>
    </div>
  </div>
  `;

  document
    .querySelector(`#${Setting.Code}_OK`)
    .addEventListener('click', function () {
      let a = Setting.OnOK();
      if (a || a == undefined) {
        tmpBox.remove();
      }
    });

  if (Setting.Mode == 'confirm' && typeof Setting.OnClose == 'function') {
    document
      .querySelector(`#${Setting.Code}_Cancel`)
      .addEventListener('click', function () {
        let a = Setting.OnClose();
        if (a || a == undefined) {
          tmpBox.remove();
        }
      });
  }
  document.querySelector(`#${Setting.Code}_OK`).focus();
};
